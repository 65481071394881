import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import { DeleteUser } from "./DeleteUser";
import {
  URL,
  token,
  LoadingIcon,
  formSelectStyles,
  validateEmail,
  SuccessMessage,
  LoadingTable,
} from "../../../components/Utils";

import "../../../stylesheets/SubuserInfo.scss";
import { LoadingTitle, Title } from "../../../components/Title";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../../components/scripts/functions";

export const NewSubuser = () => {
  const { id, subuserId } = useParams();
  const creatingUser = subuserId === "new-user" ? true : false;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [accountNo, setAccountNo] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [password, setPassword] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [client, setClient] = useState({
    name: "",
    email: "",
    role: "",
  });

  const getUser = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/admin/users/subusers/${subuserId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token()}` },
      });
  
      const data = await resp.json();
      if (resp.ok) {
        const subuser = data.subuser;
        setClient({ name: subuser.name, email: subuser.email, role: subuser.role.toString() });
  
        // Ensure linkedAccounts is updated properly
        setLinkedAccounts(subuser.linked_users.map((user) => user.id));
      } else {
        console.error("Error fetching subuser data:", resp.statusText);
      }
    } catch (error) {
      console.error("Error fetching subuser:", error);
    }
    setLoading(false);
  };
  

  const showError = (message) => {
    setErrorMsg(message);
    setTimeout(() => setErrorMsg(""), 5000);
  };

  const showSuccess = (message) => {
    setSuccessMsg(message);
    setTimeout(() => setSuccessMsg(""), 5000);
  };

  const handleUserUpload = async (e) => {
    e.preventDefault();
  
    console.log("Linked Accounts before sending:", linkedAccounts); // Debugging
  
    let curClient = {
      ...client,
      linkedAccounts: [...linkedAccounts], // Ensure it's correctly updated
      company_id: id,
    };
  
    if (password) {
      curClient.password = password;
    }
  
    setLoading(true);
    loadingAnimation(true);
  
    try {
      const resp = await fetch(
        creatingUser ? `${URL}/admin/users/subusers` : `${URL}/admin/users/subusers/${subuserId}`,
        {
          method: creatingUser ? "POST" : "PATCH",
          headers: { "Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token()}` },
          body: JSON.stringify(curClient),
        }
      );
  
      const data = await resp.json();
      if (resp.ok) {
        showSuccess(data.message);
      } else {
        showError(data.message || "Could not update user");
      }
    } catch (error) {
      showError("An error occurred while updating the user.");
    } finally {
      setLoading(false);
      loadingAnimation(false);
    }
  };
  

  const getAccount = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/admin/users/${id}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token()}` },
      });

      const data = await resp.json();
      if (resp.ok) {
        const fetchedAccounts = data.company.accounts || [];
        setAccounts(fetchedAccounts);

        if (creatingUser) {
          setLinkedAccounts(fetchedAccounts.map((account) => account.id));
        }
      } else {
        console.error("Error fetching account data:", resp.status, resp.statusText);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
    setLoading(false);
  };

  const handleToggleAccount = (accountId) => {
    setLinkedAccounts((prev) => {
      if (prev.includes(accountId)) {
        return prev.filter((id) => id !== accountId); // Remove if already selected
      } else {
        return [...prev, accountId]; // Add new selection
      }
    });
  };
  

  useEffect(() => {
    if (!creatingUser) {
      getUser();
    }
    getAccount();
  }, []);

  return (
    <>
      {!creatingUser && !client && !loading ? (
        <>
          <LoadingTitle />
          <div id="create-user-page" className="page-content">
            <LoadingTable />
          </div>
        </>
      ) : (
        <>
          <div className="top">
            <Title
              title={`Users`}
              links={[
                { link: "/", title: "Home" },
                { link: "/clients", title: "Clients" },
                { link: `/clients/edit-client/${id}`, title: `${accountNo}` },
                creatingUser
                  ? { link: `/users/new-user`, title: `New User` }
                  : {
                    link: `/users/${id}/subuser/${subuserId}`,
                    title: `${client.name}`,
                  },
              ]}
            />
          </div>
          <div id="create-user" className="page-content">
            <div className="body">
              <section className="_1 user-info mobile-column">
                <div className="left">
                  <p className="_1 f-16 l-black bold">User Information</p>
                </div>
              </section>

              <section className="_2 email-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Email Address</p>
                  </div>
                  <div className="right input flex ai-c">
                    <div className="icon">
                      <FaEnvelope />
                    </div>
                    <input
                      type="email"
                      placeholder="Username"
                      defaultValue={creatingUser ? "" : `${client.email}`}
                      onChange={(e) => {
                        let aux = { ...client };

                        aux.email = e.target.value;

                        setClient(aux);
                      }}
                    />
                  </div>
                </div>
              </section>
              <section className="_3 email-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Full Name</p>
                  </div>
                  <div className="right input flex ai-c">
                    <div className="icon">
                      <FaUser />
                    </div>
                    <input
                      type="text"
                      placeholder="Full name"
                      defaultValue={creatingUser ? "" : `${client.name}`}
                      onChange={(e) => {
                        let aux = { ...client };
                        aux.name = e.target.value;
                        setClient(aux);
                      }}
                    />
                  </div>
                </div>
              </section>
              <section className="_4 password-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Password</p>
                    <p className="_2 f-14 light-text regular">
                      If left empty, the user will get an email requesting they
                      set a password.
                    </p>
                  </div>
                  <div className="right input flex ai-c">
                    <div className="icon">
                      <FaLock />
                    </div>
                    <input
                      type={viewPassword ? "text" : "password"}
                      placeholder="Password"
                      defaultValue={""}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <div
                      className="icon view-password"
                      onClick={() => setViewPassword(!viewPassword)}
                    >
                      {viewPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
              </section>
              <section className="_6 account-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Linked Accounts</p>
                    <p className="_2 f-14 light-text regular">Select which accounts this subuser can view.</p>
                  </div>
                  <div className="right input flex-column">
                    {accounts.map((account) => (
                      <div key={account.id} className="flex ai-c toggle-row">
                        <p className="f-14 l-black regular">{account.account_no}</p>
                        <label className="toggle-switch">
                          <input type="checkbox" checked={linkedAccounts.includes(account.id)} onChange={() => handleToggleAccount(account.id)} />
                          <span className="slider"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <section className="_5 email-container">
                <div className="container mobile-column">
                  <div className="left">
                    <p className="_1 f-16 l-black regular">Position</p>
                  </div>
                  <Select
                    styles={customSelectStyles}
                    defaultValue={client.role}
                    onChange={(e) => {
                      setClient({ ...client, role: e.value });
                    }}
                    options={[
                      { label: "Manager", value: "4" },
                      { label: "User", value: "5" },
                    ]}
                    className="custom-select"
                    placeholder={client.role === "4" ? "Manager" : "User"}
                  />

                </div>
              </section>
              <div className="buttons flex ai-c">
                <a
                  href=""
                  className="button f-12 white regular green-bg"
                  onClick={handleUserUpload}
                >
                  {loading ? <LoadingIcon /> : "Save"}
                </a>
                {!creatingUser && (
                  <DeleteUser id={subuserId} client={id} from={"single"} />
                )}
              </div>
            </div>
            {errorMsg && <p className="error-message">{errorMsg}</p>}
            {successMsg && <p className="success-message">{successMsg}</p>}
          </div>
        </>
      )}
    </>
  );
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    padding: "3px 12px",
    minHeight: "none",
    width: "100%",
    minWidth: "none",
    border: "1px solid var(--border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};
