import { useState, useEffect } from "react";
import { FaAngleUp, FaAngleDown, FaUser, FaEnvelope } from "react-icons/fa";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import {
    URL,
    token,
    SuccessMessage,
    customSelectStyles,
} from "../../components/Utils";
export const DeliveryOrderTrucker = ({
    tid,
    isNew,
    userId,
    addTrucker,
    closeModal,
    user
}) => {
    const [opened, setOpened] = useState(isNew);
    const [name, setName] = useState("");
    const [displayName, setDisplayName] = useState("New Trucker");
    const [mainEmail, setMainEmail] = useState("");
    const [ccEmails, setCcEmails] = useState([""]);
    const [numberOfEmailsError, setNumberOfEmailsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [existingTruckers, setExistingTruckers] = useState([]);
    const [fetching, setFetching] = useState(true);
    const cookies = new Cookies();
    const URL = process.env.REACT_APP_API_URL;

    const openTab = () => {
        setOpened(!opened);
    };

    const addEmailInput = (e) => {
        e.preventDefault();
        if (ccEmails.length < 10) {
            setCcEmails([...ccEmails, ""]);
        } else {
            setNumberOfEmailsError(true);
        }
    };

    const handleUpdateEmail = (i, value) => {
        const updatedEmails = [...ccEmails];
        updatedEmails[i] = value;
        setCcEmails(updatedEmails);
    };

    const handleDeleteEmail = (i) => {
        const updatedEmails = [...ccEmails];
        updatedEmails.splice(i, 1);
        setCcEmails(updatedEmails);
        if (updatedEmails.length < 10) {
            setNumberOfEmailsError(false);
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleMainEmailChange = (e) => {
        setMainEmail(e.target.value);
    };

    const fetchUserSettings = async () => {
        setFetching(true);
        try {
            const response = await fetch(`${URL}/admin/settings/user/${user.id}`, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token()}`,
                },
            });

            const data = await response.json();

            if (response.status === 200) {
                setExistingTruckers(data.user?.truckers || []);
            } else {
                setError("Failed to fetch user settings.");
            }
        } catch (e) {
            setError("An error occurred while fetching user settings.");
            console.error(e);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        fetchUserSettings();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newTrucker = {
            name,
            email: mainEmail,
            cc_emails: ccEmails.filter((email) => email),
        };

        const truckersData = {
            truckers: [
                ...existingTruckers.map(trucker => ({
                    name: trucker.name,
                    email: trucker.trucker_emails[0]?.email || "",
                    cc_emails: trucker.trucker_emails[0]?.cc_emails || [],
                })),
                newTrucker
            ],
        };

        try {
            const response = await fetch(`${URL}/admin/settings/user/save/${user.id}`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token()}`,
                },
                body: JSON.stringify(truckersData),
            });

            if (response.ok) {
                const result = await response.json();
                toast.success("Trucker created successfully");
                addTrucker(result.truckers[result.truckers.length - 1]); 
                setDisplayName(name);
                if (closeModal) closeModal();
                if (typeof window.getShipmentData === "function") {
                    window.getShipmentData();
                }
            } else {
                toast.error("Failed to create trucker");
            }
        } catch (error) {
            toast.error("An error occurred while creating the trucker");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="individual-header" onClick={openTab} style={{ pointerEvents: fetching ? 'none' : 'auto', opacity: fetching ? 0.5 : 1 }}>
                <h3 className="name">{displayName}</h3>
                <div className="arrow">{opened ? <FaAngleUp /> : <FaAngleDown />}</div>
            </div>
            {opened && (
                <div className="trucker-form" style={{ padding: "20px", maxWidth: "600px", backgroundColor: "#f4f4f4", borderRadius: "5px", pointerEvents: fetching ? 'none' : 'auto', opacity: fetching ? 0.5 : 1 }}>
                    {fetching ? (
                        <div style={{ textAlign: 'center' }}>Loading...</div>
                    ) : (
                        <>
                            <div className="input-section name" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <div className="input-container name">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>Name</label>
                                    <div className="input" style={{ position: "relative" }}>
                                        <FaUser style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={handleNameChange}
                                            style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input-section render-emails" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <div className="input-container main-email">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>Main Email Address</label>
                                    <div className="bottom" style={{ position: "relative" }}>
                                        <FaEnvelope style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                        <input
                                            type="text"
                                            value={mainEmail}
                                            onChange={handleMainEmailChange}
                                            style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cc-emails">
                                    <label className="input-label" style={{ fontSize: "14px", marginBottom: "5px" }}>CC Email Addresses</label>
                                    {ccEmails.map((email, i) => (
                                        <div className="existing-email" key={i}>
                                            <div className="bottom" style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "5px" }}>
                                                <div className="input" style={{ position: "relative", flex: "1" }}>
                                                    <FaEnvelope style={{ position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)", color: "grey" }} />
                                                    <input
                                                        type="text"
                                                        value={email}
                                                        onChange={(e) => handleUpdateEmail(i, e.target.value)}
                                                        style={{ paddingLeft: "35px", flex: "1", padding: "10px 10px 10px 35px", backgroundColor: "white", borderRadius: "5px", border: "1px solid #d3d3d3" }}
                                                    />
                                                </div>
                                                <button
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: "35px",
                                                        width: "35px",
                                                        borderRadius: "5px",
                                                        border: "1px solid #707070",
                                                        backgroundColor: "white",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteEmail(i);
                                                    }}
                                                >
                                                    <p style={{ margin: "0" }}>—</p>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="input-section add-email" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                {numberOfEmailsError && (
                                    <p
                                        style={{
                                            color: "var(--red)",
                                            marginBottom: "16px",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Maximum 10 emails per trucker.
                                    </p>
                                )}
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                                    <button
                                        style={{
                                            backgroundColor: "var(--green)",
                                            width: "46%",
                                            border: "none",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "5px 10px",
                                            height: "fit-content",
                                        }}
                                        onClick={addEmailInput}
                                    >
                                        Add New Email
                                    </button>
                                    <button
                                        style={{
                                            backgroundColor: "var(--green)",
                                            width: "40%",
                                            border: "none",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "5px 10px",
                                            height: "fit-content",
                                            marginLeft: "auto"
                                        }}
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? <span>Loading...</span> : <p style={{ margin: "0" }}>Save</p>}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default DeliveryOrderTrucker;
