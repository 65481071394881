import React, { useEffect } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { URL, token, navigate } from "../../components/Utils";
import "../../stylesheets/DeleteClient.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router";

import { loadingAnimation } from "../../components/scripts/functions";

export const DeleteClient = ({ id, from, reloadClients }) => {
  const navigate = useNavigate();
  const params = useParams();

  const deleteClient = async () => {
    loadingAnimation(true);
    try {
      const resp = await fetch(`${URL}/admin/users/company/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const data = await resp.json();
      if (resp.status == 200) {
        console.log(data);
        loadingAnimation(false);

        // Call reload function to refresh the main Clients list
        reloadClients();

        navigate("/clients");
      } else {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      console.log(e);
      loadingAnimation(false);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to delete.",
      message: "Are you sure you want to delete this Client?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteClient(),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      overlayClassName: "delete-client-overlay",
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  return (
    <div className="container" id="delete-client">
      <div onClick={submit}>
        {from === "table" ? <HiOutlineTrash /> : <Button />}
      </div>
    </div>
  );
};


const Button = () => {
  return (
    <button
      className="delete-user-button"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      Delete
    </button>
  );
};
