import React, { useState, useEffect } from "react";
import { Title, LoadingTitle } from "../../components/Title";
import "../../stylesheets/HandleClients.scss";
import {
  URL,
  token,
  validateEmail,
  SuccessMessage,
  LoadingIcon,
  LoadingTable,
} from "../../components/Utils";

import { FaUser, FaEnvelope, FaTimes, FaBuilding, FaPlus, FaMinus } from "react-icons/fa";

import { DeleteClient } from "./DeleteClient";

import { useParams, useNavigate } from "react-router-dom";
import { Subusers } from "./Subusers/Subusers";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions";

export const NewClient = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [client, setClient] = useState();
  const [emailError, setEmailError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [accounts, setAccounts] = useState([{ account_no: "", email: "", name: "" }]);
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const [newClientInfo, setNewClientInfo] = useState({
    account_no: "",
    email: "",
    name: "",
  });

  const [saved, setSaved] = useState({
    display: "none",
  });

  const uploadClient = async (e) => {
    e.preventDefault();
    setSaving(true);

    const payload = {
      role: 3,
      company_name: companyName,
      company_primary_contact: companyContact,
      company_email: companyEmail,
      password: password || undefined,
      accounts,
    };

    if (id) {
      payload.company_id = id;
    }

    const url = id
      ? `${URL}/admin/users/${id}/update`
      : `${URL}/admin/register/clients`;

    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(payload),
      });

      if (resp.status === 200) {
        setSaved({ display: "block" });
        setTimeout(() => {
          setSaved({ display: "none" });
        }, 2500);

        if (!id) {
          navigate("/clients");
        }
      } else {
        const errorData = await resp.json();
        console.error("Error:", errorData);
        toast.error("Something went wrong while saving the client.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving the client.");
    } finally {
      setSaving(false);
    }
  };

  const getClientData = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/admin/users/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (resp.status === 200) {
        const data = await resp.json();

        setClient(data);
        setCompanyName(data.company.name);
        setCompanyContact(data.company.company_primary_contact || "");
        setCompanyEmail(data.company.company_email);
        setAccounts(
          data.company.accounts.map((account) => ({
            account_no: account.account_no,
            email: account.email,
            name: account.name,
            subusers: account.subusers,
          }))
        );
      } else {
        toast.error("Failed to fetch client data.");
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
      toast.error("Something went wrong while fetching client data.");
    } finally {
      setLoading(false);
    }
  };

  const addAccount = () => {
    setAccounts([...accounts, { account_no: "", email: "", name: "" }]);
    setErrors([...errors, { account_no: false, email: false, name: false }]);
  };

  const removeAccount = (index) => {
    setAccounts(accounts.filter((_, i) => i !== index));
    setErrors(errors.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedAccounts = [...accounts];
    updatedAccounts[index][field] = value;
    setAccounts(updatedAccounts);

    const updatedErrors = [...errors];
    if (field === "account_no" && value.trim() === "") {
      updatedErrors[index] = { ...updatedErrors[index], account_no: true };
    } else if (field === "email" && !validateEmail(value)) {
      updatedErrors[index] = { ...updatedErrors[index], email: true };
    } else {
      updatedErrors[index] = { ...updatedErrors[index], [field]: false };
    }
    setErrors(updatedErrors);
  };


  useEffect(() => {
    if (client) {
      let aux = { ...newClientInfo };

      aux.name = client.name;
      aux.email = client.email;
      aux.account_no = client.account_no;

      setNewClientInfo(aux);
    }
  }, [client]);

  useEffect(() => {
    if (id) {
      getClientData();
    }
  }, [id]);

  return (
    <>
      {(!client || loading) && id ? (
        <>
          <LoadingTitle />
          <div id="home" className="page-content">
            <LoadingTable />
          </div>
        </>
      ) : (
        <>
          <Title
            title={
              id ? `Edit ${companyName}'s Info` : `New Client`
            }
          />
          <div id="home" className="page-content handle-users">
            <form id="handle-users" onSubmit={uploadClient}>
              <div className="section title">
                <h3>Company Information</h3>
              </div>
              <div className="section" id="company_name">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="subtitle">Company Name</p>
                  </div>
                  <div className="right">
                    <div className="input">
                      <div className="input-icon">
                        <FaBuilding />
                      </div>
                      <input
                        type="text"
                        value={companyName}
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          setCompanyName(value);
                        }}
                        placeholder="Enter Company Name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" id="company_contact">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="subtitle">Company Contact</p>
                  </div>
                  <div className="right">
                    <div className="input">
                      <div className="input-icon">
                        <FaBuilding />
                      </div>
                      <input
                        type="text"
                        value={companyContact}
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          setCompanyContact(value);
                        }}
                        placeholder="Enter Company Primary Contact"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="section" id="company_email">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="subtitle">Company Email</p>
                  </div>
                  <div className="right">
                    <div className="input">
                      <div className="input-icon">
                        <FaEnvelope />
                      </div>
                      <input
                        type="email"
                        value={companyEmail}
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          setCompanyEmail(value);
                        }}
                        placeholder="Enter Company Email"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="section" id="company_password">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="subtitle">Password</p>
                  </div>
                  <div className="right">
                    <div className="input">
                      <div className="input-icon">
                        <FaUser />
                      </div>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {accounts.length > 0 &&
                accounts.map((account, index) => (
                  <div key={index} className="account-section">
                    <div className="section" id={`account_no_${index}`}>
                      <div className="section-wrapper">
                        <div className="left">
                          <p className="sub-title">Account No.</p>
                        </div>
                        <div className="right">
                          {errors[index]?.account_no && (
                            <p className="error-message">
                              Please enter a valid Account Number
                            </p>
                          )}
                          <div
                            className={`input ${errors[index]?.account_no ? "error" : ""}`}
                          >
                            <div className="input-icon">
                              <FaUser />
                            </div>
                            <input
                              type="text"
                              placeholder="Enter Account Number"
                              value={account.account_no}
                              onChange={(e) =>
                                handleInputChange(index, "account_no", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {accounts.length > 1 && (
                      <div className="remove-account" style={{ marginTop: "10px" }}>
                        <p
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          Delete Account
                        </p>
                        <FaMinus
                          style={{
                            position: "relative",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => removeAccount(index)}
                        />
                      </div>
                    )}
                  </div>
                ))}

              <div className="add-account" style={{ marginTop: "20px" }}>
                <p
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  Add Account
                </p>
                <FaPlus
                  style={{
                    position: "relative",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={addAccount}
                />
              </div>

              <SuccessMessage style={saved} />
              <div className="buttons">
                <button
                  id="save-btn"
                  type="submit"
                  style={
                    errors.some((error) => error?.account_no)
                      ? { opacity: ".5", pointerEvents: "none" }
                      : {}
                  }
                >
                  {saving ? <LoadingIcon /> : "Save"}
                </button>
                {id && <DeleteClient id={client?.id} from={"individual"} />}
              </div>
            </form>
            {id && (
              <div id="subusers-table">
                <div className="section title">
                  <h3>Subusers</h3>
                </div>
                {console.log("User ID: ", id)}
                {accounts.length > 0 ? (
                  <>
                    {console.log("Accounts Data: ", client?.company?.subusers)}
                    <Subusers
                      userId={id}
                      subusers={client?.company?.subusers || []}
                      reload={() => getClientData()}
                    />
                  </>
                ) : (
                  <p>No Subusers Available</p>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
